import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    Table,
    Button,
    Spin,
    Form,
    Select,
    Row,
    Col,
    Tooltip,
    Input,
    Popconfirm,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled, MailOutlined, PhoneOutlined, RotateLeftOutlined, PrinterOutlined, SearchOutlined,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify, getLangs } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";
import MaskedInput from "antd-mask-input";

const {Option} = Select;
const ContactInfos = (props) => {
    const mainUrl = apiRoutes.contact.main
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState({});
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "position", value: t('type'), con: false },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify, getLangs } = props;


    const cancelEditing = () => {
        setEditing(null);
        form.resetFields();
    };


    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(`/${mainUrl}/${i}`).then((res) => {
            let names = res.data
            let obj = {
                ...res.data,
            };
            names.locals.forEach((name) => {
                obj[`address_${name.local}`] = name.address;
            });
            form.setFieldsValue(obj);
        });
    };

    const savePosition = async (values) => {
        let obj = {
            ...values,
            id:1,
            locals: props.langs.map((l, index) => {
                return {
                    local: l.language_iso,
                    address: values[`address_${l.language_iso}`] ,
                };
            }),
        };

        await admin
            .put(`/${mainUrl}/1`, obj)
            .then((res) => {
                notify("", true);
                getPositions();
                cancelEditing();
            })
            .catch((err) => {
                notify(err.response.data.message, false);
            });

    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl).then((res) => {
            setSpin(false);
            setPositions(
                res.data
            );
        });
    };

    useEffect(() => {
        getPositions();
        getLangs();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Əlaqə məlumatları</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="bg-white animated fadeInLeft">
                  <div className="p-2">
                      <ul>
                          <li className={'mb-15'}>
                              <div className="flex flex-between">
                                  <span className="font-weight-bold">
                                     <PhoneOutlined className={'mr-15'} />  Telefon
                                  </span>
                                  <span>{positions.phone}</span>
                              </div>
                          </li>
                          <li className={'mb-15'}>
                              <div className="flex flex-between">
                                  <span className="font-weight-bold">
                                    <PrinterOutlined className={'mr-15'} />  Fax
                                  </span>
                                  <span>{positions.fax}</span>
                              </div>
                          </li>
                          <li className={'mb-15'}>
                              <div className="flex flex-between">
                                  <span className="font-weight-bold">
                                     <MailOutlined className={'mr-15'} />  Email
                                  </span>
                                  <span>{positions.email}</span>
                              </div>
                          </li>
                          <li className={'mb-15'}>
                              <div className="flex flex-between">
                                  <span className="font-weight-bold">
                                     <SearchOutlined className={'mr-15'} /> Address
                                  </span>
                                  <span>{positions.address}</span>
                              </div>
                          </li>
                      </ul>
                      <div className="flex flex-end">
                          <Button onClick={()=>{setEditingObject(1)}} type={'primary'}>
                              {t('edit')}
                          </Button>
                      </div>
                  </div>
              </div>
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("edit")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Address</p>
                        {props.langs ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name={`address_${l.language_iso}`}
                                                rules={[whiteSpace(t("inputError"))]}
                                            >
                                                <Input ref={nameInput} />
                                            </Form.Item>
                                            <div className="input-lang">{l.language_iso}</div>
                                        </div>
                                    );
                                })}

                            </div>
                        ) : (
                            <Spin size={"large"} />
                        )}

                        <p className="mb-5 mt-10">Enail</p>
                        <Form.Item
                            className="mb-5"
                            validateTrigger="onChange"
                            name={`email`}
                            rules={[whiteSpace(t("inputError"))]}
                        >
                            <Input ref={nameInput} />
                        </Form.Item>


                        <p className="mb-5 mt-10">Telefon</p>
                        <Form.Item
                            className={"mb-0 w-100 mr-10"}
                            validateTrigger="onChange"
                            name={'phone'}
                            rules={[
                                noWhitespace(t("inputError")),
                                {
                                    min: 18,
                                    message:
                                        "Əlaqə nömrəsi tam daxil edilməlidir !",
                                },
                            ]}
                        >
                            <MaskedInput
                                className={"mr-10"}
                                mask="(+994WW) WWW-WW-WW"
                                placeholder="(+99412) 310-23-00"
                                {...props}
                                formatCharacters={{
                                    W: {
                                        validate(char) {
                                            return /\w/.test(char);
                                        },
                                        transform(char) {
                                            return char.toUpperCase();
                                        },
                                    },
                                }}
                            />
                        </Form.Item>

                        <p className="mb-5 mt-10">Fax</p>
                        <Form.Item
                            className={"mb-0 w-100 mr-10"}
                            validateTrigger="onChange"
                            name={'fax'}
                            rules={[
                                noWhitespace(t("inputError")),
                                {
                                    min: 18,
                                    message:
                                        "Əlaqə nömrəsi tam daxil edilməlidir !",
                                },
                            ]}
                        >
                            <MaskedInput
                                className={"mr-10"}
                                mask="(+994WW) WWW-WW-WW"
                                placeholder="(+99412) 310-23-00"
                                {...props}
                                formatCharacters={{
                                    W: {
                                        validate(char) {
                                            return /\w/.test(char);
                                        },
                                        transform(char) {
                                            return char.toUpperCase();
                                        },
                                    },
                                }}
                            />
                        </Form.Item>

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify, getLangs })(ContactInfos);


