import React, {Component} from 'react';
import {connect} from "react-redux";
import {notify , getLangs} from "../../../../redux/actions";
import {whiteSpace , noWhitespace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    DatePicker,
    Button,
    Switch, TimePicker, Select,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import JoditEditor from "jodit-react";
import moment from "moment";
import {apiRoutes} from "../../../../const/apiroutes";
const {Option} = Select

class EditNews extends Component {
    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = apiRoutes.posts.news
    categoryUrl = apiRoutes.admin.newsCategory
    supportUrl = apiRoutes.supports.support.main
    id =  this.props.match.params.id

    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        categories:[],
        supports:[],
        isActive:false,
        type:null,
        file: null,
        fileList: [],
        previewImage: "",
        previewVisible: false,
    };

    onChange = ({ fileList: newFileList }) => {
        this.setState({fileList:newFileList})
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        admin
            .post(apiRoutes.fileUpload.image, form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                this.setState({file: res.data})
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    componentDidMount() {
        this.props.getLangs();
        if (this.id) {
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}
            const getPost = async () => {
                await admin.get(this.mainUrl + '/' + this.id).then((res) => {
                    let arr = [...this.state.fileList];
                    this.setState({file:res.data.photo});
                    console.log(res.data.content)
                    arr.push({
                        uid: res.data.photo.id,
                        url: res.data.photo.url,
                    });
                    obj[`date`] =  moment(res.data.createDate)
                    obj[`projectNumber`] =  res.data.projectNumber
                    obj[`supportNumber`] =  res.data.supportNumber
                    this.setState({
                        fileList:arr,
                        isActive: res.data.showStatus,
                        type:res.data.newsType,
                    });
                    res.data.local.forEach((d) => {
                        obj[`title_${d.local}`] = d.title;
                        obj[`body_${d.local}`] = d.content;
                        datas[`body_${d.local}`] = d.content;
                    });
                    obj['news_category_id'] = res.data.newsCategoryId
                    obj['newsType'] = res.data.newsType
                    this.setState({
                        aboutt:datas,
                    });
                    if(this.formRef.current !== null){
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
            };

            getPost();
        }
        const getCategories = async () => {
            await admin.get(this.categoryUrl , {params:{per_page:4000}}).then((res) => {
                this.setState({
                    categories:res.data
                });
            });
        };
        const getSupports = async () => {
            await admin.get(this.supportUrl , {params:{per_page:4000}}).then((res) => {
                this.setState({
                    supports:res.data
                });
                console.log(res)
            });
        };

        getCategories();
        getSupports();

    }



    saveItem = async (values) => {
        let obj = {
            newsType : values.newsType,
            supportNumber: values.supportNumber,
            projectNumber: values.newsType === 2 ? values.supportNumber : values.newsType === 3 ? values.projectNumber  :  undefined ,
            createDate:values.date,
            showStatus: this.state.isActive,
            newsCategoryId: values.news_category_id,
            category: values.category,
            photo: this.state.file,
            local: this.props.langs.map((l) => {
                return {
                    local: l.language_iso,
                    title: values[`title_${l.language_iso}`],
                    content: values[`body_${l.language_iso}`],
                };
            }),
        };
        if (!this.id) {
            await admin
                .post(this.mainUrl, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        } else {
            obj["id"] = this.props.match.params.id;
            await admin
                .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        }
    };


    errorGenerator(error){
        notify('Xəta baş verdi', false);
    }

    render() {
        let { langs , t,  getLangs, notify } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Xəbər{this.id ? 'i redaktə et' : '  əlavə et'} </span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/news`,
                                    state: { locales: null, name: this.props.location.state.name },
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
                        {/*    <Spin size={"large"} />*/}
                        {/*</div>*/}
                        <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                <Row gutter={[8, 8]}>
                                    <Col xs={12}>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`photo`}
                                            required
                                        >
                                            {/*<ImgCrop*/}
                                            {/*    className={"w-100"}*/}
                                            {/*    rotate*/}
                                            {/*    aspect={1024 / 576}*/}
                                            {/*    grid*/}
                                            {/*>*/}
                                            <Upload
                                                accept=".png, .jpg , .jpeg"
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                fileList={this.state.fileList}
                                                onChange={this.onChange}
                                                onPreview={this.handlePreview}
                                                customRequest={this.setUploadFile}
                                                beforeUpload={null}
                                            >
                                                {this.state.fileList.length < 1 && "+ Yüklə"}
                                            </Upload>
                                            {/*</ImgCrop>*/}
                                        </Form.Item>
                                    </Col>

                                    <Col md={12} sm={12} xs={24}>
                                        <p className={"mb-15"}>Ad</p>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`title_${l.language_iso}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.language_iso}</span>
                                            </div>
                                        ))}

                                        <Form.Item
                                            label={'Xəbər kateqoriyası'}
                                            name={"news_category_id"}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {
                                                    this.state.categories.map((w, i) => {
                                                        return (
                                                            <Option key={i} value={w.id}>
                                                                {w.name}
                                                            </Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>






                                        <Form.Item
                                            label={'Növ'}
                                            name={"newsType"}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                onChange={(e)=>{this.setState({type:e})}}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                <Option  value={1}>
                                                     Xəbər
                                                </Option>
                                                <Option  value={2}>
                                                    Bitmiş müraciət
                                                </Option>
                                                <Option  disabled value={3}>
                                                    Bitmiş layihə
                                                </Option>
                                            </Select>
                                        </Form.Item>

                                        {this.state.type === 2 ?
                                            <Form.Item
                                                label={'Bitmiş müraciətlər'}
                                                name={"supportNumber"}
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <Select
                                                    showSearch
                                                    className={'w-100'}
                                                    notFoundContent={null}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        this.state.supports.map((w, i) => {
                                                            return (
                                                                <Option key={i} value={w.supportNumber}>
                                                                    {w.fullName}
                                                                </Option>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>  : ''
                                        }


                                        {this.state.type === 3 ?
                                            <Form.Item
                                                label={'Bitmiş layihələr'}
                                                name={"projectNumber"}
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <Select
                                                    showSearch
                                                    className={'w-100'}
                                                    notFoundContent={null}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        this.state.supports.map((w, i) => {
                                                            return (
                                                                <Option key={i} value={w.supportNumber}>
                                                                    {w.fullName}
                                                                </Option>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>  : ''
                                        }

                                    </Col>


                                    <Col xs={24}>
                                        <div className="flex mb-15">
                                            <Switch
                                                className={"mr-15"}
                                                checked={this.state.isActive}
                                                onChange={() => {
                                                    this.setState({isActive:!this.state.isActive});
                                                }}
                                            />
                                            <span className="mr-15">Status:</span>
                                            <span className={this.state.isActive ? "green" : "red"}>
                                                 {this.state.isActive ? "Aktiv" : "Deaktiv"}
                                                </span>
                                        </div>

                                        <p className={"mb-15"}>Tarix və saat</p>
                                        <div className="flex">
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`date`}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <DatePicker format={['DD-MM-YYYY']} />
                                            </Form.Item>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`date`}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <TimePicker format={'hh:mm'} />
                                            </Form.Item>
                                        </div>

                                    </Col>



                                    <Col xs={24}>
                                        {/*<div className="mb-10">Kontent</div>*/}
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <div className="mb-10">
                                                    {l.name}
                                                </div>
                                                <Form.Item
                                                    name={`body_${l.language_iso}`}
                                                    validateTrigger="onChange"
                                                    // getValueFromEvent={(event, editor) => {
                                                    //     const dat = editor.getData();
                                                    //     return dat;
                                                    // }}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <JoditEditor
                                                        ref={this.editor}
                                                        value={this.state.aboutt[`about_${l.language_iso}`]}
                                                        tabIndex={1} // tabIndex of textarea
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/news`,
                                                    state: {
                                                        locales: null,
                                                        name: this.props.location.state.name,
                                                    },
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditNews);
export default connect(mapStateToProps, { notify , getLangs })(exp);
