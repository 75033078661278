import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Button,
} from "antd";
import {noWhitespace} from "../../../../utils/rules";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import JoditEditor from "jodit-react";

import admin from "../../../../const/api";
import {apiRoutes} from "../../../../const/apiroutes";

class EditAboutUs extends Component {
    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = apiRoutes.about.about
    constructor(props) {
        super(props);
    }

    state = {
        spin: false,
        about: {},
        file: null,
        fileList: [],
        previewImage: "",
        previewVisible: false,
    };


    onChange = ({ fileList: newFileList }) => {
        this.setState({fileList:newFileList})
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        admin
            .post(apiRoutes.fileUpload.image, form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                this.setState({file: res.data})
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };



    getPost = async () => {
        let obj = {};
        let abouts = {}
        await admin.get(`${this.mainUrl}/${this.props.match.params.id}`).then((res) => {
            if (res.data?.local){
                let arr = [...this.state.fileList];
                this.setState({file:res?.data?.photo});
                arr.push({ uid: res.data?.photo?.id, url: res.data.photo?.url });
                this.setState({fileList:arr});
                console.log(res.data)
                res.data.local.forEach((d) => {
                    obj[`about_${d.local}`] = d.about;
                    abouts[`about_${d.local}`] = d.about;
                });
                this.setState({about:abouts});
                this.setState({spin:false});
                if(this.formRef.current !== null){
                    this.formRef.current.setFieldsValue(obj);
                }
            }
        });
    };


    componentDidMount() {
        this.props.getLangs()
        this.getPost()
        this.formRef.current.resetFields()
        this.setState({spin:true});
    }



    saveItem = async (values) => {
        let obj = {
            photo: this.state.file,
            local: this.props.langs.map((l) => {
                return {
                    local: l.language_iso,
                    about: values[`about_${l.language_iso}`],
                };
            }),
        };
        console.log(obj)
        if (!this.props.match.params.id) {
            await admin
                .post(`${this.mainUrl}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    console.log(err.response.data.message)
                    notify(err.response.data.message, false);
                });
        } else {
            obj["id"] = this.props.match.params.id;
            await admin
                .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };


    render() {
        let { t } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Şirkət haqqında</span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/about-us`,
                                    state: { locales: null },
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {(this.state.spin && !this.props.langs) ? (
                            <div className="flex animated fadeIn p-2 bg-white all-center">
                                <Spin size={"large"} />
                            </div>
                        ) : (
                            <div className="p-2 animated edit fadeInUp bg-white">
                                <Form ref={this.formRef} onFinish={this.saveItem} layout="vertical">
                                    <Row gutter={[8, 8]}>


                                        <Col md={8} xs={24}>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`photo`}
                                                required
                                            >
                                                {/*<ImgCrop*/}
                                                {/*    className={"w-100"}*/}
                                                {/*    rotate*/}
                                                {/*    aspect={2000 / 2000}*/}
                                                {/*    grid*/}
                                                {/*>*/}
                                                    <Upload
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={this.state.fileList}
                                                        onChange={this.onChange}
                                                        onPreview={this.handlePreview}
                                                        customRequest={this.setUploadFile}
                                                        beforeUpload={null}
                                                    >
                                                        {this.state.fileList.length < 1 && "+ Yüklə"}
                                                    </Upload>
                                                {/*</ImgCrop>*/}
                                            </Form.Item>
                                        </Col>

                                        <Col md={24} sm={24} xs={24}>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <div className="mb-10">
                                                        {l.name}
                                                    </div>
                                                    <Form.Item
                                                        name={`about_${l.language_iso}`}
                                                        validateTrigger="onChange"
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.about[`about_${l.language_iso}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>

                                                </div>
                                            ))}
                                        </Col>
                                        <Col xs={24}>
                                            <div className={"flex"}>
                                                <Button className={"mr-15"} htmlType="submit">
                                                    {t("save")}
                                                </Button>
                                                <Link
                                                    to={{
                                                        pathname: `/about-us`,
                                                        state: { locales: null },
                                                    }}
                                                >
                                                    <Button type={"primary"}>{t("cancel")}</Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditAboutUs);
export default connect(mapStateToProps, { notify, getLangs })(exp);
