import axios from "axios";
import siteUrl from "./const";
import {logOut} from "../redux/actions";

export const admin = axios.create({
  baseURL: `${siteUrl}api/root`,
  headers: { "Content-Type": "application/json" },
});

admin.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = localStorage.getItem("access_token")
      ? "Bearer " + localStorage.getItem("access_token")
      : null;
    config.headers["accept-language"] = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "az";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// admin.interceptors.response.use(
//     function (response) {
//         localStorage.setItem('error' , false)
//         return response;
//     },
//     function (error) {
//         console.log(JSON.stringify(error))

//         if (error.response) {
//             if (error.response.status === 401) {
//                 logOut()
//                 localStorage.setItem('error' , true)
//                 localStorage.removeItem("access_token");
//                 window.location.reload();
//             } else {
//                 console.log('xeta')
//             }
//             return Promise.reject(error);
//         }
//         else{
//             localStorage.setItem('error' , false)
//         }
//     }
// );

admin.interceptors.response.use(
  (response) => {
      console.log(response.status)
      return response;
  },
  (error) => {
      console.log(error)
      return Promise.reject(error);
  }
);
export default admin;

