import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Spin,
    Popconfirm, Select,
} from "antd";
import {
    PicCenterOutlined,
    DeleteFilled,
    EditFilled,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {apiRoutes} from "../../../../const/apiroutes";
const {Option} = Select;

function News(props) {
    let mainUrl =  apiRoutes.posts.news
    const [postlist, setPostList] = useState([]);
    const [spin, setSpin] = useState(false);
    const [newsType , setNewsType] = useState(null)
    const { t } = useTranslation();
    let [trigger, setTrigger] = useState(0);

    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "image", value: "Logo", con: false },
        { key: "is_active", value: "Status", con: false },
        { key: "limit_exists", value: "Limit", con: false },
        { key: "id", value: "", con: false },
    ];

    const initialColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: "Logo",
            dataIndex: "photo",
            key: "9",
            render: (i) => {
                return <img className={"tableImage"} src={i.url} alt="" />;
            },
        },
        {
            title: t("name"),
            dataIndex: "name",
            key: "2",
        },
        {
            title: "Kateqoriya",
            dataIndex: "newsCategory",
            key: "6",
            render: (i) => {
               return  (
                    <span >{i.name}</span>
                )
            },
        },
        {
            title: "",
            dataIndex: "id",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Link
                                to={{
                                    pathname: `/news/edit/${i}`,
                                    state: {
                                        locales: postlist.find((p) => {
                                            return p.id == i;
                                        })?.locales,
                                    },
                                }}
                            >
                                <Button className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                            </Link>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePost(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const { notify } = props;

    const deletePost = async (i) => {
        if (i === 0 || i) {
            await admin
                .delete(`${mainUrl}/${i}`)
                .then((res) => {
                    setTrigger(++trigger);
                    notify("silindi", true);
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };

    const getPostList = (newsType) => {
        setSpin(true);
        admin.get(mainUrl , {params : {newsType: newsType ? newsType : null}}).then((res) => {
            res.data && setSpin(false);
            console.log(res.data)
            setPostList(
                res.data.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        tableIndex: index + 1,
                        name: d.title,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPostList(newsType);
    }, [t, trigger , newsType]);

    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Postlar</span>
                        </div>
                        <div>
                            <Link
                                to={{
                                    pathname: `/news/create`,
                                    state: { locales: null },
                                }}
                            >
                                <Button type={"primary"}>Əlavə et</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="p-2 mt-10 bg-white">
                        <Select
                            showSearch
                            placeholder={'Növ'}
                            className={'w-100'}
                            onChange={(e)=>{setNewsType(e)}}
                            notFoundContent={null}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            <Option  value={false}>
                                Hamısı
                            </Option>
                            <Option  value={1}>
                                Xəbər
                            </Option>
                            <Option  value={2}>
                                Bitmiş müraciət
                            </Option>
                            <Option   value={3}>
                                Bitmiş layihə
                            </Option>
                        </Select>
                    </div>

                </Col>

                <>

                    {spin ? (
                        <Col xs={24}>
                            <div className="flex animated fadeInUp bg-white all-center p-2">
                                <Spin size={"large"} />
                            </div>
                        </Col>
                    ) : (
                        <Col xs={24}>
                            <Table
                                size="small"
                                className="bg-white animated fadeIn"
                                columns={initialColumns}
                                dataSource={convertColumns(postlist, cols)}
                                pagination={{
                                    pageSize: 25,
                                    current_page: 1,
                                }}
                            />
                        </Col>
                    )}
                </>
            </Row>
        </div>
    );
}


export default connect(null, { notify })(News);
