import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs , notify} from "../../../../redux/actions";
import {noWhitespace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Button,
    InputNumber, Select,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import JoditEditor from "jodit-react";
import moment from "moment"
import {apiRoutes} from "../../../../const/apiroutes";
import MaskedInput from "antd-mask-input";
import TextArea from "antd/es/input/TextArea";
const {Option} = Select

class EditFreeSupport extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        spin:false,
        aboutt: {},
        file: null,
        fileList: [],
        previewImage: "",
        previewVisible: false,
    };


    onRemove = (file , fileList) =>{
        let id = file.id
        let list = fileList
        let index = list.map(x => {
            return x.id;
        }).indexOf(id);
        list.splice(index, 1);
        this.setState({
            fileList:list
        })
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image", file, filename);
        admin
            .post(apiRoutes.upload[`image`], form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                let imgArr = [...this.state.fileList]
                imgArr.push(res.data)
                this.setState({fileList: imgArr})
            })
            .catch((err) => onError());
    };


    getPost = async () => {
        this.setState({spin:true})
        let obj = {};
        await admin.get(`${apiRoutes.supports.freeSupport.main}/${this.props.match.params.id}`).then((res) => {
            this.setState({
                spin:false,
                fileList:res.data?.images,
            });
            if(this.formRef.current !== null){
                this.formRef.current.setFieldsValue({
                    ...res.data
                });
            }
        });
    };



    componentDidMount() {
        if (this.props.match.params.id) {
            this.formRef.current.resetFields()
            this.getPost();
        }
    }


    saveItem = async (values) => {
        this.setState({spin:true})
        let obj = {
            ...values,
            images:this.state.fileList,
        };
        if (this.props.match.params.id) {
            obj['id'] = this.props.match.params.id
            await admin
                .put(`${apiRoutes.supports.freeSupport.main}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.setState({spin:false})
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.setState({spin:false})
                    notify(err.response, false);
                });
        }
        else{
            await admin
                .post(`${apiRoutes.supports.freeSupport.main}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.setState({spin:false})
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.setState({spin:false})
                    notify(err.response, false);
                });
        }
    };


    render() {
        let { t } = this.props;

        return (
            <Spin spinning={this.state.spin}>
                <div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                                <div className="page-name">
                                    <PicCenterOutlined className="f-20 mr5-15" />
                                    <span className="f-20 bold">Pulsuz ver</span>
                                </div>
                                <Link
                                    to={{
                                        pathname: `/free-supports`,
                                    }}
                                >
                                    <Button type={"primary"}>{t("cancel")}</Button>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24}>
                            {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
                            {/*    <Spin size={"large"} />*/}
                            {/*</div>*/}
                            <div className={`p-2 animated edit fadeInUp bg-white`}>
                                <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                    <Row gutter={[8, 8]}>
                                        <p>Şəkilllər</p>
                                        <Col xs={24} className={'gallery'}>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`photo`}
                                                required
                                            >
                                                {/*<ImgCrop*/}
                                                {/*    className={"w-100"}*/}
                                                {/*    rotate*/}
                                                {/*    aspect={1024 / 576}*/}
                                                {/*    grid*/}
                                                {/*>*/}
                                                    <Upload
                                                        accept="image/*"
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={this.state.fileList}
                                                        onRemove={(file)=>{this.onRemove(file , this.state.fileList)}}
                                                        onPreview={this.handlePreview}
                                                        customRequest={this.setUploadFile}
                                                        beforeUpload={null}
                                                    >
                                                        {this.state.fileList.length < 100 &&  <Button className={'w-100'} type={'primary'}>+ Şəkil yüklə</Button>}
                                                    </Upload>
                                                {/*</ImgCrop>*/}
                                            </Form.Item>
                                        </Col>



                                        <Col md={8} sm={8} xs={24}>
                                            <p className={"mb-15"}>Ad Soyad Ata adı</p>
                                            <div className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`fullName`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        <Col md={8} sm={8} xs={24}>
                                            <p className={"mb-15"}>Address</p>
                                            <div className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`address`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                            </div>
                                        </Col>


                                        <Col xs={8}>
                                            <p className={"mb-15"}>Telefon</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`phone`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                    {
                                                        min: 12,
                                                        message:
                                                            "Əlaqə nömrəsi tam daxil edilməlidir !",
                                                    },
                                                ]}
                                            >
                                                <MaskedInput
                                                    className={"mr-10"}
                                                    mask="+\9\9\4 WW WWW WW WW"
                                                    placeholder="+994123102300"
                                                    {...this.props}
                                                    formatCharacters={{
                                                        W: {
                                                            validate(char) {
                                                                return /\w/.test(char);
                                                            },
                                                            transform(char) {
                                                                return char.toUpperCase();
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={8} sm={8} xs={24}>
                                            <p className={"mb-15"}>Məhsulun adı</p>
                                            <div className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`nameProduct`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                            </div>
                                        </Col>




                                        <Col xs={24}>
                                            <p className={"mb-15"}>Qeyd</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`note`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                ]}
                                            >
                                                <TextArea rows={'5'} className="w-100" />
                                            </Form.Item>
                                        </Col>


                                        <Col xs={24}>
                                            <div className={"flex"}>
                                                <Button className={"mr-15"} htmlType="submit">
                                                    {t("save")}
                                                </Button>
                                                <Link
                                                    to={{
                                                        pathname: `/free-supports`,
                                                    }}
                                                >
                                                    <Button type={"primary"}>{t("cancel")}</Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        visible={this.state.previewVisible}
                        title={false}
                        footer={null}
                        onCancel={() => {
                            this.setState({previewVisible:false})
                        }}
                    >
                        <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                    </Modal>
                </div>
            </Spin>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditFreeSupport);
export default connect(mapStateToProps, { notify, getLangs })(exp);

