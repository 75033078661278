import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Spin,
  Table,
  Button,
  Form,
  Select,
  Input,
  Popconfirm,
  Switch,
  InputNumber,
  Dropdown,
  DatePicker,
} from "antd";
import {
  PicCenterOutlined,
  DeleteFilled,
  EditFilled,
  ClearOutlined,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { notify, getLangs } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const { Option } = Select;
function Report(props) {
  let mainUrl = "PaymentReport";
  const [form] = Form.useForm();
  const [postlist, setPostList] = useState([]);
  const [selectedType, setSelectedType] = useState(0);
  const [anonymous,setAnonymous]=useState(false)
  const [spin, setSpin] = useState(false);
  const { t } = useTranslation();
  let [trigger, setTrigger] = useState(0);
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "orderId", value: "Trankzasiya nömrəsi", con: true },
    { key: "name", value: "Göndərən", con: true },
    { key: "email", value: "Email", con: true },
    { key: "amount", value: "Məbləğ", con: true },
    { key: "supportPersonFullName", value: "Qəbul edən", con: true },

    // { key: "type", value: "Fərdi şəxs/Şirkət", con: true },
  ];

  const initialColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: "Trankzasiya nömrəsi",
      dataIndex: "orderId",
      key: "2",
    },
    {
      title: "Göndərən",
      dataIndex: "name",
      key: "3",
    },
     {
      title:"Email",
      dataIndex: "email",
      key: "9",
    },
    {
      title: "Məbləğ",
      dataIndex: "amount",
      key: "4",
    },
    {
      title: "Qəbul edən",
      dataIndex: "supportPersonFullName",
      key: "5",
    },

    {
      title: "Fərdi şəxs/Şirkət",
      dataIndex: "type",
      key: "6",
      render: (i) => {
        if (i === 1) {
          return <span>Fərdi Şəxs</span>;
        }
        if (i === 2) {
          return <span>Şirkət</span>;
        }
      },
    },
    {
      title: "Anonimlik statusu",
      dataIndex: "isanonymous",
      key: "7",
      render: (i) => {
        return i === true ? "anonim" : "anonim deyil";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "8",
    },
  ];

  const { notify } = props;

  // const deletePost = async (i) => {
  //     if (i === 0 || i) {
  //         await aictia
  //             .delete(`${mainUrl}/${i}`)
  //             .then((res) => {
  //                 setTrigger(++trigger);
  //                 notify("silindi", true);
  //             })
  //             .catch((res) => {
  //                 notify(res.err, false);
  //             });
  //     }
  // };

  const savePosition = async (values) => {
    let obj = {
      ...values,
    };
    getPostList( obj);
    console.log(obj)
  };

 

  
  //     setSpin(true);
  //     await admin.get(mainUrl.main , {params:{...filters , status}}).then((res) => {
  //         console.log(res.data)
  //         setSpin(false);
  //         setPositions(
  //             res.data.map((p, index) => {
  //                 return {
  //                     key: index + 1,
  //                     ...p,
  //                     index: index + 1,
  //                     data:{
  //                         id:p.id,
  //                         status:p.status,
  //                         hideShowStatus:p.hideShowStatus
  //                     },
  //                     hideShow:{
  //                         id:p.id,
  //                         hideShowStatus:p.hideShowStatus
  //                     }
  //                 };
  //             })
  //         );
  //     });
  // };

  const getPostList = (obj) => {
    setSpin(true);
    admin.get(mainUrl, { params: obj}).then((res) => {
      res.data && setSpin(false);
      setPostList(
        res.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1,
          };
        })
      );
    });
  };
  useEffect(() => {
    getPostList();
  }, [t, trigger]);
  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Hesabat</span>
            </div>
          </div>
        </Col>
        <>
          <Col lg={24} xs={24}>
            <Spin spinning={spin}>
              <Form layout="vertical" onFinish={savePosition} form={form}>
                <div className="w-100 p-1 mb-15 bg-white">
                  <Row gutter={[10, 10]}>
                    <Col md={4} sm={8} xs={20}>
                      <Form.Item
                        className="mb-5"
                        validateTrigger="onChange"
                        name={`Type`}
                      >
                        <Select
                          showSearch
                          placeholder={"Fərdi Şəxs/ Şirkət"}
                          className={"w-100"}
                          //   value={selectedType}
                          onChange={(e) => {
                            setSelectedType(e);
                            // getPostList(e, {});
                          }}
                          notFoundContent={null}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option value={1}>Fərdi Şəxs</Option>
                          <Option value={2}>Şirkət</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={4} sm={8} xs={20}>
                      <div className={"w-100 flex"}>
                        <Form.Item
                          className="mb-5 w-100"
                          validateTrigger="onChange"
                          name={`Name`}
                        >
                          <Input
                            className=" w-100"
                            placeholder="Göndərən"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col md={4} sm={8} xs={20}>
                      <Form.Item name={"Isanonymous"}>
                        <Select placeholder="Anonimlik Statusu" >
                          <Option value={true}>Anonim</Option>
                          <Option value={false}>Anonim Deyil</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={4} sm={8} xs={20}>
                      <div className={"w-100 flex"}>
                        <Form.Item
                          className="mb-5 w-100"
                          validateTrigger="onChange"
                          name={`OrderId`}
                        >
                          <Input
                            className=" w-100"
                            placeholder="Tranzaksiya Nomresi "
                          />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col md={2} sm={12} xs={24}>
                      <Form.Item name={" "}>
                        <Button
                          type="primary"
                          size={"large"}
                          className="w-100 f-13"
                          htmlType="submit"
                        >
                          {t("search")}
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col md={2} sm={12} xs={24}>
                      <Form.Item name={" "}>
                        <Button
                          type="primary"
                          size={"large"}
                          className="w-100"
                          htmlType="submit"
                          onClick={() => {
                            getPostList(0, {});
                            form.resetFields();
                          }}
                        >
                          <ClearOutlined />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Form>

              <div className={"overflow-y-auto bg-white"}>
                <Table
                  size="small"
                  className="bg-white w-100 animated fadeInLeft"
                  columns={initialColumns}
                  dataSource={convertColumns(postlist, cols)}
                  pagination={{
                    pageSize: 10,
                    current_page: 1,
                    total: postlist.length,
                  }}
                />
              </div>
            </Spin>
          </Col>
          {/* <Col xs={24}>
                            <Table
                                size="small"
                                className="bg-white animated fadeIn"
                                columns={initialColumns}
                                dataSource={convertColumns(postlist, cols)}
                                pagination={{
                                    pageSize: 10,
                                    current_page: 1,
                                    total: postlist.length,
                                }}
                            />
                        </Col> */}
        </>
      </Row>
    </div>
  );
}

export default connect(null, { notify })(Report);
