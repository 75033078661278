import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs , notify} from "../../../../redux/actions";
import {noWhitespace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    DatePicker,
    Button,
    InputNumber, Select,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import JoditEditor from "jodit-react";
import moment from "moment"
import {apiRoutes} from "../../../../const/apiroutes";
import MaskedInput from "antd-mask-input";
const {Option} = Select

class EditSupport extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        spin:false,
        aboutt: {},
        file: null,
        fileList: [],
        videoList:[],
        subjects:[],
        previewImage: "",
        previewVisible: false,
    };


    onRemove = (file , fileList) =>{
        let id = file.id
        let isImage =  file.type.includes('image')
        let list = fileList
        let index = list.map(x => {
            return x.id;
        }).indexOf(id);
        list.splice(index, 1);
        if (isImage){
            this.setState({
                fileList:list
            })
        }
        else{
            this.setState({
                videoList:list
            })
        }
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        let isImage =  file.type.includes('image')
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image", file, filename);
        admin
            .post(apiRoutes.upload[`${isImage ? 'image': 'video'}`], form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                if (isImage){
                    let imgArr = [...this.state.fileList]
                    imgArr.push(res.data)
                    this.setState({fileList: imgArr})
                }
                else{
                    let videoArr = [...this.state.videoList]
                    videoArr.push(res.data)
                    this.setState({videoList: videoArr})
                }
            })
            .catch((err) => onError());
    };


    getPost = async () => {
        this.setState({spin:true})
        let obj = {};
        let datas = {}
        await admin.get(`${apiRoutes.supports.support.main}/${this.props.match.params.id}`).then((res) => {
            obj[`startDate`] =  moment(res.data.startDate)
            obj[`endDate`] =  moment(res.data.endDate)
            obj[`neededAmount`] =  res.data.neededAmount
            obj[`subjectId`] =  res.data.subjectId
            obj[`accumulatedAmount`] =  res.data.accumulatedAmount
            obj[`pin`] =  res.data.pin
            obj[`phone`] =  res.data.phone
            res.data.locals.forEach((d) => {
                obj[`fullName_${d.local}`] = d.fullName;
                obj[`adress_${d.local}`] = d.adress;
                obj[`title_${d.local}`] = d.title;
                obj[`description_${d.local}`] = d.description;  
                datas[`description_${d.local}`] = d.description;
            });
            this.setState({
                spin:false,
                aboutt:datas,
                fileList:res.data?.images,
                videoList:res.data?.videos,
            });
            if(this.formRef.current !== null){
                this.formRef.current.setFieldsValue(obj);
            }
        });
    };

    getSubjects = async () =>{
        await admin.get(apiRoutes.admin.subject).then((res) => {
            this.setState({subjects: res.data})
        });
    }

    componentDidMount() {
        this.props.getLangs()
        if (this.props.match.params.id) {
            this.formRef.current.resetFields()
            this.getSubjects();
            this.getPost();
        }
    }


    saveItem = async (values) => {
        this.setState({spin:true})
        let obj = {
            id:this.props.match.params.id,
            pin:values.pin,
            neededAmount:values.neededAmount,
            accumulatedAmount: values.accumulatedAmount,
            phone:values.phone,
            subjectId: values.subjectId,
            endDate:moment(values.endDate).format("YYYY-MM-DD") ,
            startDate:moment(values.startDate).format("YYYY-MM-DD") ,
            locals: this.props.langs.map((l) => {
                return {
                    local: l.language_iso,
                    fullName: values[`fullName_${l.language_iso}`],
                    adress: values[`adress_${l.language_iso}`],
                    description: values[`description_${l.language_iso}`],
                    title: values[`title_${l.language_iso}`],
                };
            }),
            images:this.state.fileList,
            videos:this.state.videoList
        };

        await admin
            .put(`${apiRoutes.supports.support.main}/${this.props.match.params.id}`, obj)
            .then((res) => {
                this.props.notify("", true);
                this.setState({spin:false})
                this.formRef.current.resetFields();
                window.history.back();
            })
            .catch((err) => {
                this.setState({spin:false})
                notify(err.response, false);
            });
    };


    render() {
        let { t } = this.props;

        return (
            <Spin spinning={this.state.spin}>
                <div>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                                <div className="page-name">
                                    <PicCenterOutlined className="f-20 mr5-15" />
                                    <span className="f-20 bold">Dəstəklər</span>
                                </div>
                                <Link
                                    to={{
                                        pathname: `/supports`,
                                    }}
                                >
                                    <Button type={"primary"}>{t("cancel")}</Button>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24}>
                            {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
                            {/*    <Spin size={"large"} />*/}
                            {/*</div>*/}
                            <div className={`p-2 animated edit fadeInUp bg-white`}>
                                <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                    <Row gutter={[8, 8]}>
                                        <p>Şəkilllər</p>
                                        <Col xs={24} className={'gallery'}>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`photo`}
                                                required
                                            >
                                                {/*<ImgCrop*/}
                                                {/*    className={"w-100"}*/}
                                                {/*    rotate*/}
                                                {/*    aspect={1024 / 576}*/}
                                                {/*    grid*/}
                                                {/*>*/}
                                                    <Upload
                                                        accept="image/*"
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={this.state.fileList}
                                                        onRemove={(file)=>{this.onRemove(file , this.state.fileList)}}
                                                        onPreview={this.handlePreview}
                                                        customRequest={this.setUploadFile}
                                                        beforeUpload={null}
                                                    >
                                                        {this.state.fileList.length < 100 &&  <Button className={'w-100'} type={'primary'}>+ Şəkil yüklə</Button>}
                                                    </Upload>
                                                {/*</ImgCrop>*/}
                                            </Form.Item>
                                        </Col>


                                        <Col xs={24} className={'gallery'}>
                                            <p>videolar</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`video`}
                                                required
                                            >
                                                {/*<ImgCrop*/}
                                                {/*    className={"w-100"}*/}
                                                {/*    rotate*/}
                                                {/*    aspect={1024 / 576}*/}
                                                {/*    grid*/}
                                                {/*>*/}
                                                    <Upload
                                                        accept="video/*"
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={this.state.videoList}
                                                        onRemove={(file)=>{this.onRemove(file , this.state.videoList)}}
                                                        onPreview={this.handlePreview}
                                                        customRequest={this.setUploadFile}
                                                        beforeUpload={null}
                                                    >
                                                        {this.state.fileList.length < 100 &&  <Button className={'w-100'} type={'primary'}>+ Video yüklə</Button>}
                                                    </Upload>
                                                {/*</ImgCrop>*/}
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} sm={12} xs={24}>
                                            <p className={"mb-15"}>Ad</p>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={`fullName_${l.language_iso}`}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input className="w-100" />
                                                    </Form.Item>
                                                    <span className="input-lang ">{l.language_iso}</span>
                                                </div>
                                            ))}
                                        </Col>

                                        <Col md={12} sm={12} xs={24}>
                                            <p className={"mb-15"}>Address</p>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={`adress_${l.language_iso}`}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input className="w-100" />
                                                    </Form.Item>
                                                    <span className="input-lang ">{l.language_iso}</span>
                                                </div>
                                            ))}
                                        </Col>

                                        <Col xs={8}>
                                            <p className={"mb-15"}>Başlama və bitmə tarixi</p>
                                            <div className="flex w-100">
                                                <Form.Item
                                                    className={'w-100'}
                                                    validateTrigger="onChange"
                                                    name={`startDate`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <DatePicker className={'w-100'} format={['DD-MM-YYYY']} />
                                                </Form.Item>
                                                <Form.Item
                                                    className={'w-100'}
                                                    validateTrigger="onChange"
                                                    name={`endDate`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <DatePicker className={'w-100'} format={['DD-MM-YYYY']} />
                                                </Form.Item>
                                            </div>
                                        </Col>


                                        <Col xs={8}>
                                            <p className={"mb-15"}>Telefon</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`phone`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                    {
                                                        min: 12,
                                                        message:
                                                            "Əlaqə nömrəsi tam daxil edilməlidir !",
                                                    },
                                                ]}
                                            >
                                                <MaskedInput
                                                    className={"mr-10"}
                                                    mask="+\9\9\4 WW WWW WW WW"
                                                    placeholder="+994123102300"
                                                    {...this.props}
                                                    formatCharacters={{
                                                        W: {
                                                            validate(char) {
                                                                return /\w/.test(char);
                                                            },
                                                            transform(char) {
                                                                return char.toUpperCase();
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col md={8} sm={12} xs={24}>
                                            <p className={"mb-15"}>Mövzu</p>
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name={`subjectId`}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <Select
                                                    showSearch
                                                    notFoundContent={null}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.subjects.map((s, i)=>(
                                                        <Option key={i}  value={s.id}>
                                                            {s.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={8}>
                                            <p className={"mb-15"}>Fin</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`pin`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                ]}
                                            >
                                                <Input className="w-100" />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={8}>
                                            <p className={"mb-15"}>Ehtiyac olan məbləğ</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`neededAmount`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                ]}
                                            >
                                                <InputNumber className="w-100" />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={8}>
                                            <p className={"mb-15"}>Yığılan məbləğ</p>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`accumulatedAmount`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                ]}
                                            >
                                                <InputNumber className="w-100" />
                                            </Form.Item>
                                        </Col>

                                        <Col md={12} sm={12} xs={24}>
                                            <p className={"mb-15"}>Başlıq</p>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <Form.Item
                                                        validateTrigger="onChange"
                                                        name={`title_${l.language_iso}`}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input className="w-100" />
                                                    </Form.Item>
                                                    <span className="input-lang ">{l.language_iso}</span>
                                                </div>
                                            ))}
                                        </Col>

                                        <Col xs={24}>
                                            <p className={"mb-15"}>Məlumat</p>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <div className="mb-10">
                                                        {l.name}
                                                    </div>
                                                    <Form.Item
                                                        name={`description_${l.language_iso}`}
                                                        validateTrigger="onChange"
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.aboutt[`description_${l.language_iso}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>
                                                </div>
                                            ))}
                                        </Col>

                                        <Col xs={24}>
                                            <div className={"flex"}>
                                                <Button className={"mr-15"} htmlType="submit">
                                                    {t("save")}
                                                </Button>
                                                <Link
                                                    to={{
                                                        pathname: `/supports`,
                                                    }}
                                                >
                                                    <Button type={"primary"}>{t("cancel")}</Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        visible={this.state.previewVisible}
                        title={false}
                        footer={null}
                        onCancel={() => {
                            this.setState({previewVisible:false})
                        }}
                    >
                        <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                    </Modal>
                </div>
            </Spin>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditSupport);
export default connect(mapStateToProps, { notify, getLangs })(exp);

