import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  DingtalkOutlined,
  AuditOutlined, HomeOutlined, ContactsOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { logOut, setEdit } from "../../redux/actions";
import history from "../../const/history";

const { SubMenu } = Menu;

const MenuList = (props) => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([]);
  const rootSubmenuKeys = ["10","50", "21", "31", "41", "51", "61"];
  let error =  localStorage.getItem('error')
  const logOut = () => {
    localStorage.removeItem("access_token");
     props.logOut();
    history.push("/");
  };


  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      <SubMenu
          key="40"
          title={
            <span>
               <HomeOutlined />
              <span>Şirkət</span>
            </span>
          }
      >
        <Menu.Item key="41">
          <Link to={`/about-us`}>
            <span>Haqqında</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="41.2">
          <Link to={`/about`}>
            <span>Görülən işlər</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="42">
          <Link to={`/news`}>
            <span>Xəbərlər</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
          key="10"
          title={
            <span>
            <AuditOutlined />
            <span>{t("admin")}</span>
          </span>
          }
      >
        {/*<Menu.Item key="11">*/}
        {/*  <Link to={`/`}>*/}
        {/*    <span>Ana səhifə</span>*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        <Menu.Item key="12">
          <Link to={`/subject`}>
            <span>Mövzular</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link to={`/news-categories`}>
            <span>Xəbər kateqoriyaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to={`/workers`}>
            <span>İşçilər</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to={`/partners`}>
            <span>Partnyorlar</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
          key="20"
          title={
            <span>
              <DingtalkOutlined />
              <span>Dəstəklər</span>
            </span>
          }
      >
        <Menu.Item key="21">
          <Link to={`/supports`}>
            <span>Dəstək</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="221">
          <Link to={`/free-supports`}>
            <span>Pulsuz ver</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="22">
          <Link to={`/reports`}>
            <span>Hesabat</span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
          key="30"
          title={
            <span>
              <ContactsOutlined />
              <span>Əlaqə</span>
            </span>
          }
      >
        <Menu.Item key="31">
          <Link to={`/contact-info`}>
            <span>Əlaqə məlumatları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="32">
          <Link to={`/social-networks`}>
            <span>Sosial media</span>
          </Link>
        </Menu.Item>
      </SubMenu>

    </Menu>
  );
};

const mapStateToProps = ({ edit }) => {
  return { edit };
};

export default connect(mapStateToProps, { logOut, setEdit })(MenuList);
