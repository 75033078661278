import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../../Pages/Admin/Home/Home";
import Subject from "../../Pages/Admin/Subject/Subject";
import Workers from "../../Pages/Admin/Workers/Workers";
import Partners from "../../Pages/Admin/Partners/Partners";
import EditWorker from "../../Pages/Admin/Workers/EditWorker";
import Support from "../../Pages/Supports/Support/Support";
import Report from "../../Pages/Supports/Report/Report";
import SocialNetworks from "../../Pages/Contact/SocialNetworks/SocialNetworks";
import ContactInfos from "../../Pages/Contact/ContactInfos/ContactInfos";
import About from "../../Pages/Company/About/About";
import EditAbout from "../../Pages/Company/About/EditAbout";
import EditSupport from "../../Pages/Supports/Support/EditSupport";
import NewsCategories from "../../Pages/Admin/NewsCategories/NewsCategories";
import News from "../../Pages/Company/News/News";
import EditNews from "../../Pages/Company/News/EditNews";
import EditAboutContent from "../../Pages/Company/About/EditAboutContent";
import AboutContent from "../../Pages/Company/About/AboutContent";
import AboutUs from "../../Pages/Company/AboutUs/AboutUs";
import EditAboutUs from "../../Pages/Company/AboutUs/EditAboutUs";
import FreeSupport from "../../Pages/FreeSupports/Support/Support";
import EditFreeSupport from "../../Pages/FreeSupports/Support/EditFreeSupport";
function Routes(props) {
        return (
        <Switch>
            <Route exact path={`/`} component={Home} />
            <Route exact path={`/subject`} component={Subject} />
            <Route exact path={`/partners`} component={Partners} />
            <Route exact path={`/workers`} component={Workers} />
            <Route exact path={`/news`} component={News} />
            <Route exact path={`/news/edit/:id`} component={EditNews} />
            <Route exact path={`/news/create`} component={EditNews} />
            <Route exact path={`/news-categories`} component={NewsCategories} />
            <Route exact path={`/social-networks`} component={SocialNetworks} />
            <Route exact path={`/contact-info`} component={ContactInfos} />
            <Route exact path={`/about`} component={About} />
            <Route exact path={`/workers`} component={Workers} />
            <Route exact path={`/free-supports`} component={FreeSupport} />
            <Route exact path={`/free-supports/edit/:id?`} component={EditFreeSupport} />
            <Route exact path={`/free-supports/create`} component={EditFreeSupport} />
            <Route exact path={`/supports`} component={Support} />
            <Route exact path={`/supports/edit/:id?`} component={EditSupport} />
            <Route exact path={`/about/edit/:id?`} component={EditAbout} />
            <Route exact path={`/reports`} component={Report} />

            <Route exact path={`/about/:year`} component={AboutContent} />
            <Route exact path={`/about/:year/edit/:id`} component={EditAboutContent} />
            <Route exact path={`/about/:year/create`} component={EditAboutContent} />

                <Route exact path={`/about-us`} component={AboutUs} />
                <Route exact path={`/about-us/edit/:id?`} component={EditAboutUs} />


            <Route exact path={`/workers/edit/:id?`} component={EditWorker} />
            <Redirect to="/" />
            <Route path="/">
                <p className="flex all-center h-100vh">Not found</p>
            </Route>
        </Switch>
    );
}

export default Routes;
