import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Spin,
    Table,
    Button,
    Form,
    Select,
    Menu,
    Modal,
    Tooltip,
    Input,
    Popconfirm, Switch, InputNumber, Dropdown,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
    EyeFilled,
    PlayCircleOutlined,
    CloseOutlined,
    CheckSquareOutlined,
    RetweetOutlined, EyeOutlined,
} from "@ant-design/icons";

import { convertColumns } from "../../../../utils/columnconverter";
import { notify, getLangs } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {apiRoutes} from "../../../../const/apiroutes";
import Detailed from "./Detailed";
import {Link} from "react-router-dom";
const {Option} = Select;

function Support(props) {
    const mainUrl = apiRoutes.supports.support
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [visibleDetailed , setVisibleDetailed] = useState( false)
    const [detailedSearch , setDetailedSearch] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    const [viewId , setViewId] = useState(null)
    const [spin, setSpin] = useState(false);
    const cols = [
        { key: "supportNumber", value: "Dəstəyin kodu", con: true },
        { key: "fullName", value: 'Ad Soyad', con: true },
        { key: "seria", value: 'Şəxsiyyətin seriya, nömrəsi', con: true },
        { key: "neededAmound", value: 'Lazım olan məbləğ (azn)', con: true },
        { key: "accumulatedAmount", value: 'Yığılmış məbləğ (azn)', con: true },
        { key: "status", value: "Status", con: false },
        { key: "hideShow", value: "", con: false },
        { key: "data", value: "", con: false },
    ];

    // props
    const { notify, getLangs } = props;

    const changeSts = async (id , status) => {
       await admin.put(mainUrl.status + '/' + id , {
            id,status
        }).then((res)=>{
           console.log(res)
           getPositions(selectedProduct , {})
           notify("Status dəyişdirildi", true);
       }).catch((err)=>{
           notify(err.message, false);
       })
    }

    const viewMessage = (i) => {
      setViewId(i)
      setVisibleDetailed(true)
    }

    const columns = [
        {
            title: 'Dəstəyin kodu',
            key: "2",
            dataIndex: "supportNumber",
        },
        {
            title: 'Ad Soyad',
            key: "3",
            dataIndex: "fullName",
        },
        {
            title: 'Fin',
            key: "4",
            dataIndex: "pin",
        },
        {
            title: 'Lazımı məbləğ (azn)',
            key: "4",
            dataIndex: "neededAmount",
        },
        {
            title: 'Yığılan (azn)',
            key: "4",
            dataIndex: "accumulatedAmount",
        },
        {
            title: 'Status',
            key: "4",
            dataIndex: "status",
            render:(i) =>{
                return <span>
                    {i === 1 &&
                        <span className="orange">Gözləmədə</span>
                    }
                    {i === 2 &&
                    <span className="green">Təsdiq edilmiş</span>
                    }
                    {i === 3 &&
                    <span className="red">Ləğv edilmiş</span>
                    }
                    {i === 4 &&
                    <span className="blue">Bitmiş</span>
                    }
                </span>
            }
        },
        {
            title: "",
            key: "3",
            dataIndex: "hideShow",
            width: 30,
            render: (i) => {
                return (
                    <Popconfirm
                        placement="topRight"
                        title={`${i.hideShowStatus ? 'Gizlətmək' : 'Göstərmək'} istədiyinzə əminsinizmi ?`}
                        onConfirm={() => setShowStatus(!i.hideShowStatus ,i.id)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <Tooltip
                            className="ml-5"
                            title={i.hideShowStatus ? 'Gizlət' : 'Göstər'}
                            placement="bottomRight"
                        >
                            <span>
                                 <Switch checked={i.hideShowStatus} checkedChildren="Aktiv" unCheckedChildren="Gizli" />
                           </span>
                        </Tooltip>
                    </Popconfirm>
                );
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "data",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip
                            className="ml-5"
                            title={t("operations")}
                            placement="leftBottom"
                        >
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item>
                                            <div onClick={() => viewMessage(i.id)}>
                                                <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                    <EyeOutlined className="mr5-5"/>
                                                    <span>{t("detailed")}</span>
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        {i.status !== 3 &&
                                            <Menu.Item>
                                                <Link to={`/supports/edit/${i.id}`}>
                                                    <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                        <EditFilled className="mr5-5"/>
                                                        <span>{t("edit")}</span>
                                                    </div>
                                                </Link>
                                            </Menu.Item>
                                        }

                                        {i.status === 3 && (
                                            <Menu.Item>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={t("areYouSure")}
                                                    onConfirm={() => deletePosition(i.id)}
                                                    okText={t("yes")}
                                                    cancelText={t("no")}
                                                >
                                                    <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                        <DeleteFilled className="mr5-5" />
                                                        <span>{t("delete")}</span>
                                                    </div>
                                                </Popconfirm>
                                            </Menu.Item>
                                        )}
                                        {/*status*/}
                                        {i.status === 1 &&
                                            <>
                                                <Menu.Item>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title={'Təsdiqləmək istədiyinzə əminsinizmi ?'}
                                                        onConfirm={() => changeSts(i.id , 2)}
                                                        okText={t("yes")}
                                                        cancelText={t("no")}
                                                    >
                                                        <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                            <PlayCircleOutlined className="mr5-5" />
                                                            <span>Təsdiqlə</span>
                                                        </div>
                                                    </Popconfirm>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title={'Ləğv etmək istədiyinzə əminsinizmi ?'}
                                                        onConfirm={() => changeSts(i.id , 3)}
                                                        okText={t("yes")}
                                                        cancelText={t("no")}
                                                    >
                                                        <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                            <CloseOutlined className="mr5-5" />
                                                            <span>Ləğv et</span>
                                                        </div>
                                                    </Popconfirm>
                                                </Menu.Item>
                                            </>
                                        }

                                        {i.status === 2 &&
                                            <Menu.Item>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={'Bitirmək istədiyinzə əminsinizmi ?'}
                                                    onConfirm={() => changeSts(i.id , 4)}
                                                    okText={t("yes")}
                                                    cancelText={t("no")}
                                                >
                                                    <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                        <CheckSquareOutlined className="mr5-5" />
                                                        <span>Bitir</span>
                                                    </div>
                                                </Popconfirm>
                                            </Menu.Item>
                                        }

                                        {i.status === 3 &&
                                            <Menu.Item>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={'Gözləmə statusuna qaytarmaq istədiyinizə əminsinizmi ?'}
                                                    onConfirm={() => changeSts(i.id , 4)}
                                                    okText={t("yes")}
                                                    cancelText={t("no")}
                                                >
                                                    <div className="flex f-14 flex-align-center pr-1 pl-1">
                                                        <RetweetOutlined className="mr5-5" />
                                                        <span>Gözləmə statusuna qaytar</span>
                                                    </div>
                                                </Popconfirm>
                                            </Menu.Item>
                                        }
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                            >
                                <Button shape="circle" className="border-none">
                                    <EyeFilled />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    
    const setShowStatus = async (hideShowStatus , id) => {
     await admin.put(apiRoutes.supports.support.showStatus + '/' + id, {hideShowStatus, id}).then((res)=>{
          notify('', true);
          getPositions(selectedProduct ,{});
      })
      .catch((err) => {
          notify(err.response, false);
      });
     }
    

    const deletePosition = async (i) => {
        await admin
            .delete(mainUrl.main + '/' + i)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions(selectedProduct ,{});
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        getPositions(selectedProduct , values)
    };


    const getPositions = async (sts , filters) => {
        console.log(filters)
        let status = sts === 0 ? undefined : sts
        setSpin(true);
        await admin.get(mainUrl.main , {params:{...filters , status}}).then((res) => {
            console.log(res.data)
            setSpin(false);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        data:{
                            id:p.id,
                            status:p.status,
                            hideShowStatus:p.hideShowStatus
                        },
                        hideShow:{
                            id:p.id,
                            hideShowStatus:p.hideShowStatus
                        }
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions(selectedProduct ,{})
        getLangs();
    }, [t]);


    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Dəstəklər</span>
                </div>
            </Col>
            <Col lg={24} xs={24}>
                <Spin spinning={spin}>
                    <Form  layout="vertical" onFinish={savePosition} form={form} >
                        <div className="w-100 p-1 mb-15 bg-white">
                          <Row gutter={[10, 10]}>
                             <Col md={detailedSearch ? 24 : 20} xs={24}>
                                 <Form.Item
                                     className="mb-5"
                                     validateTrigger="onChange"
                                     name={`status`}
                                 >
                                     <Select
                                         showSearch
                                         placeholder={'Status'}
                                         className={'w-100'}
                                         onChange={(e)=>{setSelectedProduct(e); getPositions(e , {})}}
                                         notFoundContent={null}
                                         optionFilterProp="children"
                                         filterOption={(input, option) =>
                                             option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                         }
                                         filterSort={(optionA, optionB) =>
                                             optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                         }
                                     >
                                         <Option  value={0}>
                                             Hamısı
                                         </Option>
                                         <Option  value={1}>
                                             Gözləmə
                                         </Option>
                                         <Option  value={2}>
                                             Təsdiqlənmiş
                                         </Option>
                                         <Option  value={3}>
                                             Ləğv edilmiş
                                         </Option>
                                         <Option  value={4}>
                                             Bitmiş
                                         </Option>
                                     </Select>
                                 </Form.Item>
                             </Col>
                              {!detailedSearch &&
                                  <Col md={4} xs={24}>
                                      <Button className={'h-100 w-100'} onClick={()=>{setDetailedSearch(true)}} type={'primary'}>
                                          Ətraflı axtarış
                                      </Button>
                                  </Col>
                              }
                              {detailedSearch &&
                                  <Col xs={24}>
                                          <Row gutter={[10, 10]}>
                                              <Col md={12} xs={24}>
                                                  <div className={'w-100 flex'}>
                                                      <Form.Item
                                                          className="mb-5 w-100"
                                                          validateTrigger="onChange"
                                                          name={`MinNeededAmount`}
                                                      >
                                                           <InputNumber style={{borderRight: 0,}} className=" w-100" placeholder="Minimum ehtiyac olan məbləğ" />
                                                      </Form.Item>
                                                      <Input
                                                          className="site-input-split"
                                                          style={{
                                                              width: 30,
                                                              borderLeft: 0,
                                                              borderRight: 0,
                                                              pointerEvents: 'none',
                                                          }}
                                                          placeholder="~"
                                                          readOnly
                                                      />
                                                      <Form.Item
                                                          className="mb-5 w-100"
                                                          validateTrigger="onChange"
                                                          name={`MaxNeededAmount`}
                                                      >
                                                          <InputNumber
                                                              style={{borderLeft: 0,}}
                                                              className=" w-100" placeholder="Maximum ehtiyac olan məbləğ" />
                                                      </Form.Item>
                                                  </div>
                                              </Col>
                                              <Col md={12} xs={24}>
                                                  <div className={'w-100 flex'}>
                                                      <Form.Item
                                                          className="mb-5 w-100"
                                                          validateTrigger="onChange"
                                                          name={`MinAccumulatedAmount`}
                                                      >
                                                           <InputNumber style={{borderRight: 0,}} className=" w-100" placeholder="Minimum yığılmış məbləğ" />
                                                      </Form.Item>
                                                      <Input
                                                          className="site-input-split"
                                                          style={{
                                                              width: 30,
                                                              borderLeft: 0,
                                                              borderRight: 0,
                                                              pointerEvents: 'none',
                                                          }}
                                                          placeholder="~"
                                                          readOnly
                                                      />
                                                      <Form.Item
                                                          className="mb-5 w-100"
                                                          validateTrigger="onChange"
                                                          name={`MaxAccumulatedAmount`}
                                                      >
                                                          <InputNumber style={{borderLeft: 0,}} className=" w-100" placeholder="Maximum yığılmış məbləğ" />
                                                      </Form.Item>
                                                  </div>
                                              </Col>
                                              <Col md={8} xs={24}>
                                                  <div className={'w-100 flex'}>
                                                      <Form.Item
                                                          className="mb-5 w-100"
                                                          validateTrigger="onChange"
                                                          name={`FIN`}
                                                      >
                                                          <Input className=" w-100" placeholder="Fin" />
                                                      </Form.Item>
                                                  </div>
                                              </Col>
                                              <Col md={8} xs={24}>
                                                  <div className={'w-100 flex'}>
                                                      <Form.Item
                                                          className="mb-5 w-100"
                                                          validateTrigger="onChange"
                                                          name={`SupportNumber`}
                                                      >
                                                          <Input className=" w-100" placeholder="Dəstək nömrəsi" />
                                                      </Form.Item>
                                                  </div>
                                              </Col>
                                              <Col md={8} xs={24}>
                                                  <Form.Item
                                                      className="mb-5"
                                                      validateTrigger="onChange"
                                                      name={`HideShowStatus`}
                                                  >
                                                      <Select
                                                          showSearch
                                                          placeholder={'Görünmə statusu'}
                                                          className={'w-100'}
                                                          onChange={(e)=>{setSelectedProduct(e); getPositions(e , {})}}
                                                          notFoundContent={null}
                                                          optionFilterProp="children"
                                                          filterOption={(input, option) =>
                                                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                          }
                                                          filterSort={(optionA, optionB) =>
                                                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                          }
                                                      >
                                                          <Option  value={true}>
                                                              Aktiv
                                                          </Option>
                                                          <Option  value={false}>
                                                              Deaktiv
                                                          </Option>
                                                      </Select>
                                                  </Form.Item>
                                              </Col>
                                              <Col md={24} xs={24}>
                                                  <div className="w-100 flex-end flex">
                                                      <Button className={'ml-15'} htmlType={'submit'}>Axtar</Button>
                                                      <Button className={'ml-15'} type={'primary'} htmlType={'button'} onClick={()=>{getPositions(0 ,{} ) ; form.resetFields() }} >Təmizlə</Button>
                                                      <Button className={'ml-15'}  type={'primary'} htmlType={'button'} onClick={()=>{setDetailedSearch(false)}}>{t('cancel')}</Button>
                                                  </div>
                                              </Col>
                                          </Row>
                                  </Col>
                              }
                          </Row>
                        </div>
                    </Form>

                    <div className={'overflow-y-auto bg-white'}>
                        <Table
                            // loading={spin}
                            size="small"
                            className="bg-white w-100 animated fadeInLeft"
                            columns={columns}
                            dataSource={convertColumns(positions, cols)}
                            pagination={{
                                pageSize: 10,
                                current_page: 1,
                                total: positions.length,
                            }}
                        />
                    </div>
                </Spin>

            </Col>

            <Modal
                title={t("detailedInfo")}
                centered
                className={"padModal mediumModal"}
                visible={visibleDetailed}
                onOk={() => setVisibleDetailed(false)}
                onCancel={() => setVisibleDetailed(false)}
                footer={[]}
            >
                <Detailed setVisibleDetailed={setVisibleDetailed}  id={viewId} />
            </Modal>
        </Row>
    );
}


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify, getLangs })(Support);

