import React from 'react';

function Home(props) {
    return (
        <div></div>
    );
}


export default Home;
