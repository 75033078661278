import React, {useEffect, useState} from "react";
import {Row, Col, Button, Spin, Image} from "antd";
import { useTranslation } from "react-i18next";
import {apiRoutes} from "../../../../const/apiroutes";
import admin from "../../../../const/api";
import {connect} from "react-redux";
import { Player , BigPlayButton } from 'video-react';
import {notify} from "../../../../redux/actions";
import moment from "moment";
const Detailed = (props) => {
    const { t } = useTranslation();
    const { id  , setVisibleDetailed , detailed  , notify} = props
    const mainUrl = apiRoutes.supports.support
    const [data , setData] = useState({})
    const [spin , setSpin] = useState(false)

    const getDetailedData = (id) => {
        setSpin(true)
        admin.get(mainUrl.details + '/' + id).then((res)=>{
            setSpin(false)
            setData(res.data)
            console.log(res.data)
        }).catch((err)=>{
            notify(err.message, false);
            setVisibleDetailed(false)
        })
    }

    useEffect(() => {
        getDetailedData(id)
    } ,[detailed , id , t])

    return (
        <div>
            <Spin spinning={spin}>
                <Row gutter={[16, 16]}>
                <Col lg={12} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Fin</span>
                                        <span>{data.pin}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Nömrə</span>
                                        <span>{data.supportNumber}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Tam adı</span>
                                        <span>{data.fullName}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Əlaqə nömrəsi</span>
                                        <span>{data.phone}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Address</span>
                                        <span>{data.adress}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>

                <Col lg={12} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Başlama tarixi</span>
                                        <span>{moment(data.startDate).format("DD-MM-YYYY")}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Bitmə tarixi</span>
                                        <span>{moment(data.endDate).format("DD-MM-YYYY")}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Mövzu</span>
                                        <span>{data?.subject?.name}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Ehtiyac olan məbləğ</span>
                                        <span>{data?.neededAmount} azn</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="flex flex-between">
                                        <span>Yığılmış məbləğ</span>
                                        <span>{data?.accumulatedAmount} azn</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Col>

                <Col xs={24}>
                    <p>Məlumat</p>
                    <div className="border p-2 flex" dangerouslySetInnerHTML={{__html: data.description}} />
                </Col>
                {
                    data?.images?.length ?
                        <Col xs={24}>
                            <p>Şəkillər</p>
                            <div className={'border p-2 '}>
                                <Row gutter={[16, 16]}>
                                    <Image.PreviewGroup>
                                        {data?.images?.map((s, i) => (
                                            <Col md={6} sm={12} xs={24}>
                                                <Image
                                                    key={i}
                                                    className={'w-100'}
                                                    src={s.url}
                                                />
                                            </Col>
                                        ))}
                                    </Image.PreviewGroup>
                                </Row>
                            </div>
                        </Col> : ''
                }
                {
                    data?.videos?.length ?
                    <Col xs={24}>
                        <p>Videolar</p>
                        <div className={'border p-2 '}>
                            <Row gutter={[16, 16]}>
                                {data?.videos?.map((s, i)=>(
                                    <Col md={6} sm={12} xs={24}>
                                        <Player
                                            playsInline
                                            src={s.url}
                                        >
                                            <BigPlayButton position="center" />
                                        </Player>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col> : ''
                }
            </Row>
            </Spin>

            <div
                className="modalButtons"
                style={{ position: "absolute", bottom: "20px", right: "20px" }}
            >
                <Button className={'mr-15'} onClick={() => props.setVisibleDetailed(false)}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
};

export default connect(null, { notify })(Detailed);


