import React from 'react';
import ReactDOM from 'react-dom';
import "video-react/dist/video-react.css"
import App from './components/App';
import './assets/css/animate.css'
import './assets/css/custom/custom.css';
import 'antd/dist/antd.css';
import './assets/css/override/override.css'
import './assets/css/global/global.css';
import "./i18n";
import history from './const/history';
import { Router  , BrowserRouter , HashRouter} from 'react-router-dom';

import { Provider ,  } from 'react-redux';
import store from './redux/store';

ReactDOM.render(<Provider store={store}><HashRouter history={history}><App /></HashRouter></Provider>, document.querySelector("#root"));
