import React, {useEffect, useState, useRef} from "react";
import {
    Card,
    Table,
    Button,
    Form,
    Spin,
    Row,
    Col,
    Tooltip,
    Input,
    Popconfirm, Upload,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import admin from "../../../../const/api";
import {useTranslation} from "react-i18next";
import {whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";
import JoditEditor from "jodit-react";

const Partners = (props) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [image, setImage] = useState({});
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const editor = useRef()
    const [text, setText] = useState({
        name_az:'',
        name_en:'',
        name_ru:''
    })
    const [editing, setEditing] = useState(null);
    const cols = [
        {key: "index", value: "#", con: true},
        {key: "name", value: t("name"), con: true},
        {key: "is_active", value: "Status", con: false},
        {key: "id", value: "", con: false},
    ];
    const nameInput = useRef();
    const { notify, getLangs } = props;
    const [fileList, setFileList] = useState([]);
    const onChange = ({fileList: newFileList}) => {
        setFileList(newFileList);
        if (newFileList.length <= 0) {
            setImage(null);
        }
    };

    const setUploadFile = ({onSuccess, onError, file}) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        admin
            .post(apiRoutes.fileUpload.image , form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setImage(res.data);
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: "",
            dataIndex: "image",
            key: "9",
            render: (i) => {
                return <img className={"tableImage"} src={i.url} alt=""/>;
            },
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled/>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => {
                                    setEditingObject(i)
                                }}
                            >
                                <EditFilled/>
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const setEditingObject = async (i) => {
        setEditing(i);
        form.resetFields()
        await admin.get(`${apiRoutes.admin.partners}/${i}`).then((res) => {
            let resp = res.data
            let obj = {
                name:resp.name
            };
            let objText = {};
            resp.local.forEach((name) => {
                obj[`text_${name.local}`] = name.content;
                objText[`text_${name.local}`] = name.content;
            });
            let arr = [];
            console.log(objText)
            setImage(resp.image);
            setText(objText)
            arr.push({uid: resp.image.id, url: resp.image.url});
            setFileList(arr);
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setImage(null)
        setFileList([])
        setText({})
        setEditing(null);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await admin
            .delete(`${apiRoutes.admin.partners}/${i}`)
            .then(() => {
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            name:values.name,
            image,
            local: props.langs.map((l, index) => {
                return {
                    local: l.language_iso,
                    content: values[`text_${l.language_iso}`]
                };
            }),
        };
        if (!editing) {
            await admin
                .post(apiRoutes.admin.partners, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`${apiRoutes.admin.partners}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(apiRoutes.admin.partners).then((res) => {
            setSpin(false);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
        getLangs();
    }, [t]);


    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15"/>
                    <span className="f-20 bold">Partnyorlar</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <Form.Item
                            validateTrigger="onChange"
                            name={`photo`}
                            required
                        >
                            {/*<ImgCrop*/}
                            {/*    className={"w-100"}*/}
                            {/*    rotate*/}
                            {/*    aspect={1152 / 2048}*/}
                            {/*    grid*/}
                            {/*>*/}
                            {/*    <Upload*/}
                            {/*        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"*/}
                            {/*        listType="picture-card"*/}
                            {/*        fileList={fileList}*/}
                            {/*        onChange={onChange}*/}
                            {/*        customRequest={setUploadFile}*/}
                            {/*        beforeUpload={null}*/}
                            {/*    >*/}
                            {/*        {fileList.length < 1 && "+ Şəkil yüklə"}*/}
                            {/*    </Upload>*/}
                            {/*</ImgCrop>*/}
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={false}
                                customRequest={setUploadFile}
                                beforeUpload={null}
                            >
                                {fileList.length < 1 && "+ Şəkil yüklə"}
                            </Upload>
                        </Form.Item>


                        <p className="mb-5 mt-15">Adı</p>
                        <div className="form-lang">
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name={`name`}
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <Input ref={nameInput}/>
                            </Form.Item>
                        </div>


                        <p className="mb-5">Məlumat</p>
                        {props.langs.map((l, i) => {
                            return (
                                <div key={l.id} className="form-lang">
                                    <Form.Item
                                        className="mb-5"
                                        validateTrigger="onChange"
                                        name={`text_${l.language_iso}`}
                                    >
                                        <p>{l.name}</p>
                                        <JoditEditor
                                            onChange={(newContent) => {
                                                form.setFieldsValue({
                                                    [`text_${l.language_iso}`]: newContent
                                                })
                                            }}
                                            className={'mb-15'}
                                            ref={editor}
                                            value={text[`text_${l.language_iso}`]}
                                            tabIndex={1} // tabIndex of textarea
                                        />
                                    </Form.Item>
                                </div>
                            );
                        })}


                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify, getLangs })(Partners);







