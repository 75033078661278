export const apiRoutes = {
    upload:{
      image:'Upload/File',
      video:'Upload/File'
    },
    admin:{
        login:'auth/Login',
        language:'Language',
        subject:'Subject',
        newsCategory:'NewsCategory',
        workers:'worker',
        partners:'Partner'
    },
    posts:{
        news:'News'
    },
    fileUpload:{
        video:'Upload/File',
        image:'Upload/File'
    },
    supports:{
        support:{
            main:'Support',
            details:'Support/details',
            status:'Support/Status',
            showStatus:'Support/HideShowStatus'
        },
        freeSupport:{
            main:'FreeSupport',
            details:'FreeSupport',
            status:'FreeSupport/Status',
            showStatus:'FreeSupport/HideShowStatus'
        },
    },
    contact:{
        main:'Contact',
        socialNetwork:'SosialNetwork'
    },
    about: {
        about: 'AboutUs',
        abouts: 'ActionForYears',
        year:'Year',
    }
}
