import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    Table,
    Button,
    Spin,
    Form,
    Select,
    Row,
    Col,
    Tooltip,
    Input,
    Popconfirm,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify, getLangs } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";

const {Option} = Select;
const Subject = (props) => {
    const mainUrl = apiRoutes.admin.subject
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "position", value: t('type'), con: false },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify, getLangs } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: t("type"),
            key: "2",
            dataIndex: "subject_CategoryId",
            render: (i) => {
                return (
                    <>{renderPosition(i)}</>
                )
            }
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const renderPosition = (position) =>{
        let arr = ['Dəstək al','Pulsuz ver']
        return arr[position]
    }

    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(`/${mainUrl}/${i}`).then((res) => {
            let names = res.data
            let obj = {};
            names.locals.forEach((name) => {
                obj[`name_${name.language_iso}`] = name.name;
            });
            obj['position'] = names.subject_CategoryId
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await admin
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            Subject_CategoryId: values.position,
            locals: props.langs.map((l, index) => {
                return {
                    language_iso: l.language_iso,
                    name: values[`name_${l.language_iso}`] ,
                };
            }),
        };
        if (!editing) {
            await admin
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl).then((res) => {
            setSpin(false);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
        getLangs();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Mövzular</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Adı</p>
                        {props.langs ? (
                            <div>
                                {props.langs.map((l, i) => {
                                    return (
                                        <div key={l.id} className="form-lang">
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name={`name_${l.language_iso}`}
                                                rules={[whiteSpace(t("inputError"))]}
                                            >
                                                <Input ref={nameInput} />
                                            </Form.Item>
                                            <div className="input-lang">{l.language_iso}</div>
                                        </div>
                                    );
                                })}
                                <p className="mb-5">Növü</p>
                                <Form.Item
                                    className="mb-5"
                                    validateTrigger="onChange"
                                    name={`position`}
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Select
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        <Option  value={0}>
                                            Dəstək al
                                        </Option>
                                        <Option  value={1}>
                                            Pulsuz ver
                                        </Option>
                                    </Select>
                                </Form.Item>

                            </div>
                        ) : (
                            <Spin size={"large"} />
                        )}

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify, getLangs })(Subject);


